import { createWebHistory, createRouter } from 'vue-router'
// see    https://stackoverflow.com/questions/46590760/vue-js-2-multiple-routing-files
import routesBudgets from './routesBudgets.js'
import routesClaim from './routesClaim.js'
import routesImport from './routesImport.js'
import routesReference from './routesReference.js'
import routesReports from './routesReports.js'

import Login from '@/views/LoginView.vue'
import About from '@/views/AboutView.vue'

const routes = [
  ...routesBudgets,
  ...routesClaim,
  ...routesImport,
  ...routesReports,
  ...routesReference,
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
