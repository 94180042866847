export default [
  {
    path: '/import/ANZ',
    name: 'importANZ',
    component: function () {
      return import(
        /* webpackChunkName: "prototype" */ '../views/import/importANZ.vue'
      )
    },
  },
  {
    path: '/import/CCCU',
    name: 'importCCCU',
    component: function () {
      return import(
        /* webpackChunkName: "prototype" */ '../views/import/importCCCU.vue'
      )
    },
  },
  {
    path: '/import/Chase',
    name: 'importChase',
    component: function () {
      return import(
        /* webpackChunkName: "prototype" */ '../views/import/importChase.vue'
      )
    },
  },
  {
    path: '/import/ING',
    name: 'importING',
    component: function () {
      return import(
        /* webpackChunkName: "prototype" */ '../views/import/importING.vue'
      )
    },
  },
  {
    path: '/import/PayPal',
    name: 'importPayPal',
    component: function () {
      return import(
        /* webpackChunkName: "prototype" */ '../views/import/importPayPal.vue'
      )
    },
  },
  {
    path: '/import/Westpac',
    name: 'importWestpac',
    component: function () {
      return import(
        /* webpackChunkName: "prototype" */ '../views/import/importWestpac.vue'
      )
    },
  },
]
