<template>
  <div class="form-item">
    <div>
      <label v-if="label">{{ label }}</label>
    </div>

    <textarea
      v-bind="$attrs"
      :placeholder="label"
      class="textarea"
      :rows="rows"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
    rows: { type: [String, Number], default: 5 },
    modelValue: { type: [String, Number], default: '' },
  },
}
</script>
<style>
div.form-item {
  padding: 20px;
}
label {
  font-weight: 700;
  color: green;
}
.textarea {
  width: 100%;
}
</style>
