import axios from 'axios'
import store from '../store/index.js'

//const log = process.env.VUE_APP_SITE_SHOW_CONSOLE_LOG
const apiURL = process.env.VUE_APP_DEFAULT_PHP_URL
const apiLocation = process.env.VUE_APP_SITE_LOCATION
const postDestination = 'ContentApi.php?location=' + apiLocation
const apiSELECT = axios.create({
  baseURL: apiURL,
  withCredentials: false, // This is the default
  crossDomain: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// I want to export a JSON.stringified of response.data.text
export default {
  async get(params) {
    params.user = store.state.user
    var response = await apiSELECT.post(postDestination, params)
    return response.data
  },
  async login(params) {
    params.action = 'login'
    var response = await apiSELECT.post(postDestination, params)
    return response.data
  },
}
