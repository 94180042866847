import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      uid: null,
      expires: 0,
    },
  },
  getters: {},
  mutations: {
    LOGIN_USER(state, value) {
      state.user = value[0]
    },
    LOGOUT_USER(state) {
      state.user = {}
    },
  },
  actions: {
    loginUser({ commit }, [mark]) {
      commit('LOGIN_USER', [mark])
    },
    logoutUser({ commit }) {
      commit('LOGOUT_USER')
    },
  },
  modules: {},
})
