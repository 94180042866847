<template>
  <div id="nav">
    <link rel="stylesheet" href="/sites/default/styles/appGLOBAL.css" />
    <link rel="stylesheet" href="/sites/default/styles/cardGLOBAL.css" />
    <link rel="stylesheet" href="/sites/default/styles/envelopesGLOBAL.css" />

    <div v-on:click="toggleMenu()">
      <img v-bind:src="this.headerImage" class="nav-icon" alt="Home" />
    </div>
    <div v-if="showMenu">
      <div
        v-for="menuItem in this.menu"
        :key="menuItem.link"
        :menuItem="menuItem"
      >
        <div class="menu-card -shadow" v-if="menuItem.show">
          <div
            class="float-left"
            style="cursor: pointer"
            @click="setNewSelectedOption(menuItem.link)"
          >
            {{ menuItem.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LogService from '@/services/LogService.js'
export default {
  computed: mapState(['user']),

  data() {
    return {
      headerImage: process.env.VUE_APP_SITE_MENU_DIR + 'header.png',
      showMenu: false,
      authorized: false,
      administrator: false,
      menu: [
        {
          value: 'Logout',
          link: 'logout',
          index: 0,
          show: false,
        },
        {
          value: 'Claim',
          link: 'claim',
          index: 1,
          show: false,
        },
        {
          value: 'Import',
          link: 'import',
          index: 2,
          show: false,
        },
        {
          value: 'Reference',
          link: 'reference',
          index: 3,
          show: false,
        },
        {
          value: 'Reports',
          link: 'reports',
          index: 4,
          show: false,
        },
        {
          value: 'Tests',
          link: 'test',
          index: 6,
          show: false,
        },
        {
          value: 'Budgets',
          link: 'budgets',
          index: 6,
          show: false,
        },
      ],
    }
  },
  created() {
    this.authorized = true
    this.administrator = true
    LogService.consoleLogMessage('I finished authorization')
    var arrayLength = this.menu
    var i = 0
    for (i = 0; i < arrayLength; i++) {
      this.menu[i].show = false
    }
    if (this.authorized) {
      for (i = 0; i < arrayLength; i++) {
        this.menu[i].show = true
      }
    }
    if (!this.authorized) {
      this.menu[0].show = true
    }
  },
  methods: {
    goBack() {
      window.history.back()
    },
    toggleMenu() {
      LogService.consoleLogMessage('tried to toggle')
      if (this.showMenu) {
        this.showMenu = false
      } else {
        this.showMenu = true
      }
    },
    setNewSelectedOption(selectedOption) {
      LogService.consoleLogMessage(selectedOption)
      // code block
    },
  },
}
</script>

<style></style>
