export default [
  {
    path: '/claim/unclaimedTransactions',
    name: 'unclaimedTransactions',
    component: function () {
      return import(
        /* webpackChunkName: "prototype" */ '../views/claim/unclaimedTransactions.vue'
      )
    },
  },
]
