<template>
  <div>
    <h2>Login</h2>
    <form @submit.prevent="saveForm">
      <BaseInput
        v-model="username"
        label="Username"
        type="text"
        placeholder
        class="field"
      />

      <BaseInput
        v-model="password"
        label="Password"
        type="password"
        placeholder
        class="field"
      />

      <br />
      <br />
      <button class="button red" @click="saveForm">Login</button>
      <template v-if="wrong">
        <p class="errorMessage">Wrong username or password. Try again</p>
      </template>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContentService from '@/services/ContentService.js'
import LogService from '@/services/LogService.js'

import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      username: null,
      password: null,
      submitted: false,
      wrong: null,
    }
  },
  computed: mapState(['user']),
  validations: {
    username: { required },
    password: { required },
  },
  created() {
    localStorage.clear()
  },
  methods: {
    async saveForm() {
      if (this.submitted == false) {
        try {
          this.submitted = true
          var params = {}
          params.username = this.username
          params.password = this.password
          let data = await ContentService.login(params)
          console.log(data)
          if (data.authorized == true) {
            this.$store.dispatch('loginUser', [data])
            var stringified = JSON.stringify(data)
            localStorage.setItem('user', stringified)
            this.$router.push({ name: 'importANZ' })
          } else {
            alert('Login was not successful. Try again') //
          }
        } catch (error) {
          LogService.consoleLogError('Login.  There was an error ', error) //
        }
      }
    },
  },
}
</script>
